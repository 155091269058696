body {
  margin: 0;
  font-family: "Poppins", Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", Helvetica;
}

html, body.chakra-ui-light {
  background-color: #f7f9ff;
  scroll-behavior: smooth;
}

:root {
  --override-green2: rgba(0, 147, 121, 1);
  --primary: var(--chakra-colors-purple-700);
  --primary-dark: var(--chakra-colors-purple-900);
  --secondary: var(--chakra-colors-teal-200);
  --secondary-dark: var(--chakra-colors-teal-500);
}

* {
  position: relative;
  z-index: 2;
}